import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Owen Lenz', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to oklenz.com', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Owen',
  subtitle: "I'm a Software Engineer.",
  cta: 'About',
};

// ABOUT DATA
export const aboutData = {
  img: 'headshot.png',
  paragraphOne:
    "I'm a full-stack software engineer living in the Green Mountains of Vermont. I take a detail oriented, bottom-up approach to building things and solving problems. I really enjoy going down the rabbit hole while learning a new tool or language or delving into some esoteric corner of the software world.",
  paragraphTwo:
    "I have experience in JavaScript, Ruby and Go and I'm comfortable working with cloud technologies, particularly on AWS. Most recently I co-created",
  paragraphThree:
    ' a framework for deploying JAMstack applications with serverless functions. You can read more about it below or watch the presentation I gave with my team detailing how we built it.',
  resume: 'https://www.resumemaker.online/es.php',
  whitepaper: 'https://jolt-framework.github.io/',
};

// JOLT SECTION DATA
export const joltData = {
  title: 'Jolt',
  info: 'Jolt is an open-source framework for developing, deploying and managing JAMstack applications with serverless functions that run on AWS.',
  info2:
    'I built Jolt with a distributed team of 3 other engineers in 2021. Our initial aim was to create a tool that made it easy for developers to take advantage of the security, high-scalability and fast response times that the JAMstack model provides while leveraging serverless functions to handle computation that needs to be performed outside of the client.',
  info3:
    "As we built Jolt, we learned that the intial goal of simply handling application deployment wasn't enough to provide the ease-of-use we were looking for. To improve on our inital vision, we expanded Jolt to provide additional functionality including the ability for developers to roll back their application code and serverless functions simultaenously, perform teardowns that deprovisioned all infrastructure on the developer's AWS account and a development server that allows serverless functions to be run locally.",
  url: 'https://jolt-framework.github.io/',
  repo: 'https://github.com/jolt-framework',
  img: 'jolt_color_logo.png',
  id: nanoid(),
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'swello.png',
    title: 'Swello',
    info: 'A full-stack Trello-like SPA for project management. Features drag and drop functionality and more.',
    info2: '',
    languages: 'React/Redux, Node.js/Express, MongoDB',
    url: '',
  },
  {
    id: nanoid(),
    img: 'group-limerick.png',
    title: 'Group-Limerick',
    info: 'A multi-player web app where players take turns writing lines in a limerick before the completed limericks are displayed.',
    info2:
      'This is a popular game in my family, so I built this at the start of the pandemic so we could all play together remotely.',
    languages: 'Ruby/Sinatra, ERB, jQuery, Heroku',
    url: '',
  },
  {
    id: nanoid(),
    img: 'airlines.png',
    title: "Flykstra's Map",
    info: "Displays and filters airline routes on a world map and highlights paths between selected airports using Dijkstra's pathfinding algorithm (React, HTML, CSS).",
    info2: '',
    languages: 'Node.js/Express, PostgreSQL, vanilla JavaScript',
    url: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: "I'm looking for opportunities!",
  btn: "Let's talk",
  email: 'owen.lenz@gmail.com',
  networks: [
    {
      id: nanoid(),
      name: 'envelope',
      url: 'owen.lenz@gmail.com',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/owen-lenz/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/OwenKLenz',
    },
    {
      id: nanoid(),
      name: '',
      url: 'https://github.com/OwenKLenz',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
